import {
  IGiftItemResponse, ITicket, api, Status,
} from '@one-tree/library'
import axios from 'axios'
import { Resource } from '../types/API'
import { IGiftItemPostBody } from '../types/GiftItemTypes'
import { OrganisationFormat } from '../types/Types'

const { Categories, TicketItems } = Resource

const { Created, OK } = Status

const itemEndpointMap = new Map<OrganisationFormat, Resource>([
  [OrganisationFormat.GiftVouchers, Resource.GiftVoucherItems],
  [OrganisationFormat.SpecialOffers, Resource.SpecialOfferItems],
  [OrganisationFormat.Tickets, Resource.TicketItems],
])
export const getItemEndpointByFormat = (
  format: OrganisationFormat,
): string | undefined => itemEndpointMap.get(format)

export const getGiftItem = async (data: {
  format: OrganisationFormat
  id: string
}): Promise<IGiftItemResponse | false> => {
  const endpoint = getItemEndpointByFormat(data.format)
  if (!endpoint) return false

  const request = axios.get(`${endpoint}/${data.id}`)
  return api(request, OK)
}

export const getGiftItems = async (data: {
  format: OrganisationFormat
  archived?: boolean
}): Promise<IGiftItemResponse[] | false> => {
  const endpoint = getItemEndpointByFormat(data.format)
  if (!endpoint) return false

  const request = axios.get(
    `${endpoint}${data.archived ? '?status=inactive' : ''}`,
  )
  return api(request, OK)
}

export const patchGiftItem = async (data: {
  format: OrganisationFormat
  itemId: number
  patchOptions: IGiftItemPostBody
}): Promise<IGiftItemResponse | false> => {
  const endpoint = getItemEndpointByFormat(data.format)
  if (!endpoint) return false

  const request = axios.patch(`${endpoint}/${data.itemId}`, data.patchOptions)
  return api(request, OK)
}

export const bulkPatchGiftItems = async (data: {
  format: OrganisationFormat
  patchOptions: IGiftItemPostBody[]
}): Promise<IGiftItemResponse[] | false> => {
  const endpoint = getItemEndpointByFormat(data.format)
  if (!endpoint) return false

  const request = axios.patch(endpoint, data.patchOptions)
  return api(request, OK)
}

export const deleteGiftItem = async (data: {
  format: OrganisationFormat
  id: number
}): Promise<boolean> => {
  const endpoint = getItemEndpointByFormat(data.format)
  if (!endpoint) return false

  const request = axios.delete(`${endpoint}/${data.id}`)
  return api(request, OK)
}

export const createGiftItem = async (data: {
  format: OrganisationFormat
  giftItem: IGiftItemPostBody
}): Promise<IGiftItemResponse | false> => {
  const endpoint = getItemEndpointByFormat(data.format)
  if (!endpoint) return false

  const request = axios.post(endpoint, data.giftItem)
  return api(request, Created)
}

export const bulkRedeemTickets = async (data: {
  giftItemIds: number[]
}): Promise<ITicket[] | false> => {
  const request = axios.post(`${TicketItems}/redeem`, {
    events: data.giftItemIds,
  })
  return api(request, OK)
}

export const getEventGuestlist = async (data: {
  id: number
}): Promise<string | false> => {
  const request = axios.get(`${TicketItems}/${data.id}/guestlist`)
  return api(request, OK)
}

export const getCategoryGuestlist = async (data: {
  id: number
}): Promise<string | false> => {
  const request = axios.get(`${Categories}/${data.id}/guestlist`)
  return api(request, OK)
}
