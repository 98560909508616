import React, { ReactElement, useEffect, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import {
  Button,
  ButtonStyle,
  GiftCardStatus,
  Order,
  Table,
} from '@one-tree/library'
import Card, { CardSize } from '../components/page/Card'
import { searchGiftCards } from '../helpers/APIHelper'
import { concatObjectValues } from '../helpers/DataTransformer'
import { RoutePath } from '../types/Routes'
import { ISearchParams } from '../types/Types'
import Page from '../hoc/Page'
import CardHeader from '../components/page/CardHeader'
import CardContent from '../components/page/CardContent'
import { useOrganisation } from '../context/OrganisationProvider'
import {
  salesColumns,
  salesFilters,
  SalesTableState,
} from '../helpers/TableHelper'

export default function SearchResults(): ReactElement {
  const { organisation } = useOrganisation()
  const history = useHistory()
  const { search } = useLocation()

  const useQuery = (): URLSearchParams => new URLSearchParams(search)
  const query = useQuery()

  const [searchParams, setSearchParams] = useState<ISearchParams | null>({
    purchaserName: query.get('purchaserName') || '',
    recipientName: query.get('recipientName') || '',
    orderId: query.get('orderId') || '',
  })

  const cardTitle = searchParams ? (
    <>
      Search results for: <em>{concatObjectValues(searchParams, ' ')}</em>
    </>
  ) : (
    'All gift vouchers'
  )

  const [tableState, setTableState] = useState<SalesTableState>()

  const [voucherIdList, setVoucherIdList] = useState<string[]>()
  useEffect(() => {
    if (voucherIdList) {
      const stringList = JSON.stringify(voucherIdList)
      sessionStorage.setItem('voucherIdList', stringList)
    }
  }, [voucherIdList])

  return (
    <Page>
      <Card cardSize={CardSize.Full}>
        <CardHeader title={cardTitle}>
          <Button
            buttonStyle={ButtonStyle.Secondary}
            onClick={(): void => history.push(RoutePath.Home)}
          >
            Back home
          </Button>
          {searchParams && (
            <Button
              buttonStyle={ButtonStyle.Primary}
              onClick={(): void => setSearchParams(null)}
            >
              Clear search
            </Button>
          )}
        </CardHeader>
        <CardContent>
          <Table
            fetch={searchGiftCards}
            columns={salesColumns(
              organisation,
              tableState?.status === GiftCardStatus.Redeemed,
            )}
            options={{
              orderBy: 'purchaseDate',
              orderDirection: Order.Desc,
            }}
            search={true}
            dates={true}
            filters={salesFilters}
            rowLink={{
              route: RoutePath.VoucherDetail,
              slug: 'id',
              state: { search },
            }}
            getState={setTableState}
            defaults={searchParams || undefined}
            forgetful={true}
            getData={(data): void => {
              if (data) setVoucherIdList(data.map((row) => row.id))
            }}
          />
        </CardContent>
      </Card>
    </Page>
  )
}
