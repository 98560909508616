import React, { ReactElement, useEffect, useState } from 'react'
import {
  swap,
  GridContextProvider,
  GridDropZone,
  GridItem,
} from 'react-grid-dnd'
import styled from 'styled-components'
import {
  alertError,
  colors,
  IGiftItemResponse,
  theme,
  ZIndex,
} from '@one-tree/library'
import ScrollZone, {
  ScrollDirection,
  ScrollSpeed,
} from '../../../components/ScrollZone'
import { useOrganisation } from '../../../context/OrganisationProvider'
import { patchGiftItem } from '../../../helpers/APIItemHelper'
import useResponsive from '../../../helpers/isResponsive'
import GiftItem from './GiftItemGridItem'

const StyledGridDropZone = styled(GridDropZone)`
  border: 1px solid ${colors.lightGray};
  background-color: ${colors.lightestGray};
  border-radius: 4px;
  margin-top: 15px;
  transition: opacity 0.2s;
  &.drop-zone-loading {
    opacity: 0.8;
    pointer-events: none;
  }
`
const StyledGridItem = styled(GridItem)`
  cursor: default !important;
  &.gift-item-drag-enabled {
    cursor: grab !important;
  }
  &.gift-item-dragging {
    cursor: grabbing !important;
  }
  &:hover {
    z-index: ${ZIndex.AboveContent} !important;
  }
  display: flex;
  align-items: center;
  justify-content: center;
`
export interface IGiftItemGridProps {
  giftItemData: IGiftItemResponse[]
  fetchGiftItems: (noLoad?: true) => Promise<void>
  showControls?: boolean
}
function GiftItemGrid(props: IGiftItemGridProps): ReactElement {
  const { giftItemData, fetchGiftItems, showControls } = props
  const [items, setItems] = useState(giftItemData)
  useEffect(() => {
    setItems(giftItemData)
  }, [giftItemData])

  const [hovering, setHovering] = useState(false)
  const [loading, setLoading] = useState(false)
  const [isDragging, setIsDragging] = useState(false)

  const { organisation } = useOrganisation()
  const { format } = organisation || {}

  const onChange = async (
    sourceId: string,
    sourceIndex: number,
    targetIndex: number,
  ): Promise<void> => {
    setLoading(true)
    setIsDragging(false)
    setHovering(false)
    if (sourceIndex !== targetIndex) {
      // Swap our items visually on the grid
      setItems(swap(items, sourceIndex, targetIndex))
      const source = items[sourceIndex].id
      const target = items[targetIndex].order
      if (source && (target || target === 0) && format) {
        // Send our item's new order to the backend
        await patchGiftItem({
          format,
          itemId: source,
          patchOptions: { order: target },
        })
        // Refetch our items to ensure accurate order
        await fetchGiftItems(true)
      } else {
        alertError('There was an error saving the new order')
      }
    }
    setLoading(false)
  }

  const columns = useResponsive().isSmallDesktop ? 3 : 4
  const rowHeight = 405 + (showControls ? theme.giftItemControlPanelHeightPixels : 0)
  const totalHeight = Math.ceil(items.length / columns) * rowHeight

  const loadingClass = hovering && !loading ? 'gift-item-drag-enabled' : ''
  const itemClasses = `${loadingClass} ${isDragging ? 'gift-item-dragging' : ''}`

  return (
    <GridContextProvider onChange={onChange}>
      <ScrollZone
        disabled={!isDragging}
        speed={ScrollSpeed.Fast}
        direction={ScrollDirection.Up}
      />
      <ScrollZone
        disabled={!isDragging}
        speed={ScrollSpeed.Slow}
        direction={ScrollDirection.Up}
      />
      <StyledGridDropZone
        id="items"
        boxesPerRow={columns}
        rowHeight={rowHeight}
        style={{ height: `${totalHeight}px` }}
        disableDrag={!hovering || loading}
        className={loading ? 'drop-zone-loading' : ''}
      >
        {items.map((item: IGiftItemResponse) => (
          <StyledGridItem key={item.id} className={itemClasses}>
            <GiftItem
              item={item}
              setHovering={setHovering}
              loading={loading}
              setLoading={setLoading}
              setIsDragging={setIsDragging}
              showControls={showControls}
              fetchGiftItems={fetchGiftItems}
            />
          </StyledGridItem>
        ))}
      </StyledGridDropZone>
      <ScrollZone
        disabled={!isDragging}
        speed={ScrollSpeed.Slow}
        direction={ScrollDirection.Down}
      />
      <ScrollZone
        disabled={!isDragging}
        speed={ScrollSpeed.Fast}
        direction={ScrollDirection.Down}
      />
    </GridContextProvider>
  )
}
export default GiftItemGrid
