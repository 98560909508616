import React, { ReactElement, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'
import {
  Loading,
  alertError,
  alertSuccess,
  Button,
  ButtonStyle,
  InputGroup,
  ISelectOption,
  enumToOptions,
  SelectField,
  stringsToOptions,
  ZIndex,
  stringToOption,
  IGiftItemResponse,
  GiftItemStatus,
} from '@one-tree/library'
import CardHeader from '../../components/page/CardHeader'
import { bulkPatchGiftItems, getGiftItems } from '../../helpers/APIItemHelper'
import { RoutePath } from '../../types/Routes'
import GiftItemGrid from './giftItemGrid/GiftItemGrid'
import { filterGiftItems, typeHandler } from '../../helpers/GiftItemHelper'
import { useOrganisation } from '../../context/OrganisationProvider'
import { stripEmptyFromArray } from '../../helpers/DataTransformer'
import CardContent from '../../components/page/CardContent'
import { OrganisationFormat } from '../../types/Types'
import { IGiftItemFilters } from '../../types/GiftItemTypes'
import { itemWord } from '../../helpers/FormatHelper'
import { get } from '../../helpers/APIHelper'
import { ICategoryResponse, Resource } from '../../types/API'
import { getCategoriesAsOptions } from '../../helpers/CategoryHelper'
import { isGiftItemValid } from '../../helpers/GiftItemValidityHelper'

const StyledInputGroup = styled(InputGroup)`
  /* so the controls are above the ScrollZone */
  z-index: ${ZIndex.AboveContent};
`

function ManageGiftItems(): ReactElement {
  const [giftItemData, setGiftItemData] = useState<IGiftItemResponse[]>([])
  const [categories, setCategories] = useState<ISelectOption<number>[]>()
  const [allTypes, setAllTypes] = useState<string[]>()
  const [loading, setLoading] = useState(false)
  const history = useHistory()
  const { organisation } = useOrganisation()
  const [filteredGiftItems, setFilteredGiftItems] = useState<
    IGiftItemResponse[]
  >([])

  const [archived, setArchived] = useState(false)

  const storedFilters = sessionStorage.getItem('manageFilters')
  const initFilters = storedFilters
    ? JSON.parse(storedFilters)
    : {
      status: false,
      type: false,
      category: false,
      unfinished: false,
    }
  const [filters, setFilters] = useState<IGiftItemFilters>(initFilters)
  const updateFilters = <T extends unknown>(
    key: keyof IGiftItemFilters,
    update: T,
  ): void => {
    setFilters((prevState) => {
      const newFilterState = {
        ...prevState,
        [key]: update,
      }
      sessionStorage.setItem('manageFilters', JSON.stringify(newFilterState))
      return newFilterState
    })
  }

  const fetchGiftItems = async (noLoad?: true): Promise<void> => {
    if (!noLoad) setLoading(true)
    const res = organisation
      && (await getGiftItems({
        format: organisation.format,
        archived,
      }))
    if (res) setGiftItemData(res)
    setLoading(false)
  }

  const fetchCategories = async (): Promise<void> => {
    const res = await get<ICategoryResponse[]>({
      resource: Resource.Categories,
    })
    if (res) setCategories(getCategoriesAsOptions(res))
  }

  useEffect(() => {
    fetchGiftItems()
    if (organisation?.categoriesEnabled) fetchCategories()
  }, [archived])

  useEffect(() => {
    const filteredGiftItemData = filterGiftItems(
      giftItemData,
      filters,
      organisation,
      true,
    )
    setFilteredGiftItems(filteredGiftItemData)
    if (filters.type) {
      // If we are filtering by type, show all other types, so we can change it
      // to another type
      setAllTypes(typeHandler(giftItemData))
    } else {
      // otherwise show only types available based on the filtered results
      setAllTypes(typeHandler(filteredGiftItemData))
    }
  }, [filters, giftItemData])

  const handleMultiStatusChange = async (
    items: IGiftItemResponse[] | false,
    newStatus: GiftItemStatus,
  ): Promise<void> => {
    if (items) {
      const bulkPatchOptions = items
        // only patch valid items
        .filter((item: IGiftItemResponse) => isGiftItemValid(item, organisation))
        .map((item) => {
          // ignore items that already have the status we want
          if (item.status === newStatus) {
            return null
          }
          return { id: item.id, status: newStatus }
        })
      const filteredOptions = stripEmptyFromArray(bulkPatchOptions)
      if (!filteredOptions.length) {
        alertError(`No items eligible to be ${newStatus}`)
      } else {
        const res = organisation
          && (await bulkPatchGiftItems({
            format: organisation.format,
            patchOptions: filteredOptions,
          }))
        if (res) {
          alertSuccess(
            `${res.length} item${res.length > 1 ? 's were' : ' was'
            }  ${newStatus}`,
          )
          fetchGiftItems()
        }
      }
    }
  }

  const publishableItems = filteredGiftItems.filter(
    (item: IGiftItemResponse) => isGiftItemValid(item, organisation)
      && item.status === GiftItemStatus.Unpublished,
  )
  const unpublishableItems = filteredGiftItems.filter(
    (item: IGiftItemResponse) => item.status === GiftItemStatus.Published,
  )

  const unfinishedOption = {
    value: 'Unfinished',
    label: 'Unfinished',
  }
  const filterOptions = [...enumToOptions(GiftItemStatus), unfinishedOption]

  const publishPlural = publishableItems.length > 1 ? 's' : ''
  const publishAllMessage = `Are you sure you want to publish ${publishableItems.length} item${publishPlural}?`

  const unpublishPlural = unpublishableItems.length > 1 ? 's' : ''
  const unpublishAllMessage = `Are you sure you want to unpublish ${unpublishableItems.length} item${unpublishPlural}?`

  const getStatusValue = (): ISelectOption<string> | undefined => {
    const statusOption = filters.unfinished
      ? unfinishedOption
      : stringToOption(filters.status || '')

    // Since the actual label from backend is 'inactive'
    if (statusOption?.value === GiftItemStatus.Archived) {
      return { value: GiftItemStatus.Archived, label: 'Archived' }
    }
    return statusOption
  }

  const showingArchived = getStatusValue()?.value === GiftItemStatus.Archived
  const normalText = `Create and amend ${itemWord(
    organisation,
  )}s here. Publish and unpublish to show or hide each ${itemWord(
    organisation,
  )} on your web shop.`
  const archivedText = 'These items are archived. Click \'Restore\' to make them available again for use on your web shop.'

  return (
    <>
      <CardHeader title={`Manage ${itemWord(organisation)}s`}>
        <Button
          buttonStyle={ButtonStyle.Action}
          onClick={(): void => history.push(RoutePath.CreateItem)}
        >
          Create new
        </Button>
      </CardHeader>
      <CardContent>
        <p>{showingArchived ? archivedText : normalText}</p>
        {!showingArchived && (
          <p>Amendments made here will appear on your website.</p>
        )}
        <StyledInputGroup>
          <SelectField
            placeholder="Filter by status..."
            options={filterOptions}
            value={getStatusValue()}
            onChange={(option): void => {
              setArchived(option?.value === GiftItemStatus.Archived)

              if (option) {
                if (option === unfinishedOption) {
                  updateFilters('status', false)
                  updateFilters('unfinished', true)
                } else {
                  // if we remove unfinishedOption, the remainder is GiftItemStatus
                  // eslint-disable-next-line no-type-assertion/no-type-assertion
                  updateFilters('status', option.value as GiftItemStatus)
                  updateFilters('unfinished', false)
                }
              } else {
                updateFilters('status', false)
                updateFilters('unfinished', false)
              }
            }}
            isClearable={true}
          />
          {organisation?.format === OrganisationFormat.GiftVouchers && (
            <SelectField
              placeholder="Filter by type..."
              options={stringsToOptions(allTypes || [])}
              value={stringToOption(filters.type || '')}
              onChange={(option): void => {
                if (option) {
                  updateFilters('type', option.value)
                } else {
                  updateFilters('type', false)
                }
              }}
              isClearable={true}
              disabled={!allTypes}
            />
          )}
          {organisation?.categoriesEnabled && (
            <SelectField
              placeholder="Filter by category..."
              options={categories || []}
              value={
                categories
                && categories.find(
                  (category) => category.value === filters.category,
                )
              }
              onChange={(option): void => {
                if (option) {
                  updateFilters('category', option.value)
                } else {
                  updateFilters('category', false)
                }
              }}
              isClearable={true}
              disabled={!categories}
            />
          )}
          {publishableItems?.length && (
            <Button
              buttonStyle={ButtonStyle.Action}
              onClick={(): Promise<void> => handleMultiStatusChange(
                filteredGiftItems,
                GiftItemStatus.Published,
              )}
              showConfirmation={true}
              confirmationMessage={publishAllMessage}
            >
              Publish all
            </Button>
          )}
          {unpublishableItems?.length && (
            <Button
              buttonStyle={ButtonStyle.Secondary}
              onClick={(): Promise<void> => handleMultiStatusChange(
                filteredGiftItems,
                GiftItemStatus.Unpublished,
              )}
              showConfirmation={true}
              confirmationMessage={unpublishAllMessage}
            >
              Unpublish all
            </Button>
          )}
        </StyledInputGroup>
        {loading ? (
          <Loading fullPage={true} />
        ) : (
          <GiftItemGrid
            giftItemData={filteredGiftItems}
            fetchGiftItems={fetchGiftItems}
            showControls={true}
          />
        )}
      </CardContent>
    </>
  )
}
export default ManageGiftItems
