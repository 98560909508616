import {
  GiftItemStatus,
  GiftItemType,
  IGiftItemResponse,
  IOrganisationResponse,
  stripHTML,
} from '@one-tree/library'
import moment from 'moment'
import { OrganisationFormat } from '../types/Types'

interface ITestParams {
  giftItem: IGiftItemResponse | null
  organisation: IOrganisationResponse | null
  unfinishedOnly?: true
}

const itemTest = ({ giftItem, organisation }: ITestParams): string | false => (!giftItem || !organisation) && 'Item error'

export const valueTest = ({
  giftItem,
  organisation,
}: ITestParams): string | false => {
  const { format } = organisation || {}
  const { value } = giftItem || {}

  if (giftItem && format !== OrganisationFormat.Tickets) {
    if (!value) {
      return 'Item requires value'
    }

    if (
      parseFloat(value) < 0.01
      && giftItem.type !== GiftItemType.Complimentary
    ) {
      return 'Free items cannot be published'
    }
  }
  return false
}

export const nameTest = ({ giftItem }: ITestParams): string | false => {
  if (giftItem) {
    return !giftItem.name && 'Item requires name'
  }
  return false
}

export const typeTest = ({
  giftItem,
  organisation,
}: ITestParams): string | false => {
  if (giftItem && organisation) {
    return (
      organisation.format === OrganisationFormat.GiftVouchers
      && !giftItem.type
      && 'Item requires type'
    )
  }
  return false
}

export const categoryTest = ({
  giftItem,
  organisation,
}: ITestParams): string | false => {
  if (giftItem && organisation) {
    return (
      organisation.categoriesEnabled
      && !giftItem.categoryIds?.length
      && giftItem.type !== GiftItemType.Complimentary
      && 'Item requires category'
    )
  }
  return false
}

export const imageTest = ({ giftItem }: ITestParams): string | false => {
  if (giftItem) {
    return (
      giftItem.type !== GiftItemType.Complimentary
      && !giftItem.imageUrl?.length
      && !giftItem.imageFileId
      && 'Item requires image'
    )
  }
  return false
}

export const validityTest = ({
  giftItem,
  organisation,
}: ITestParams): string | false => {
  if (giftItem && organisation) {
    return (
      organisation.format === OrganisationFormat.GiftVouchers
      && !giftItem.validity
      && 'Item requires validity period'
    )
  }
  return false
}

const monetaryTest = ({ giftItem }: ITestParams): string | false => {
  if (giftItem) {
    const hasDecimal = giftItem.value?.includes('.') || false
    return (
      giftItem.type === GiftItemType.Monetary
      && hasDecimal
      && 'Monetary items cannot have decimals'
    )
  }
  return false
}

export const descriptionTest = ({
  giftItem,
  organisation,
}: ITestParams): string | false => {
  if (giftItem && organisation) {
    const stripped = stripHTML(giftItem.description || '')
    return (
      organisation.format === OrganisationFormat.GiftVouchers
      && !stripped
      && 'Item requires summary'
    )
  }
  return false
}

export const detailsTest = ({
  giftItem,
  organisation,
}: ITestParams): string | false => {
  if (giftItem && organisation) {
    const stripped = stripHTML(giftItem.details || '')
    return (
      organisation.format === OrganisationFormat.GiftVouchers
      && !stripped
      && 'Item requires description'
    )
  }
  return false
}

export const restrictionsTest = ({
  giftItem,
  organisation,
}: ITestParams): string | false => {
  if (giftItem && organisation) {
    const stripped = stripHTML(giftItem.restrictions || '')
    return (
      organisation.format === OrganisationFormat.SpecialOffers
      && !stripped
      && 'Item requires important information'
    )
  }
  return false
}

export const startInfoTest = ({
  giftItem,
  organisation,
}: ITestParams): string | false => {
  if (giftItem && organisation) {
    return (
      organisation.format === OrganisationFormat.Tickets
      && !giftItem.startInfo
      && 'Event requires a start date'
    )
  }
  return false
}

export const originalPriceTest = ({
  giftItem,
  organisation,
}: ITestParams): string | false => {
  if (giftItem && organisation && giftItem.originalPrice && giftItem.value) {
    return (
      organisation.format === OrganisationFormat.SpecialOffers
      && giftItem.originalPrice < parseFloat(giftItem.value)
      && 'Original price must be greater than value'
    )
  }
  return false
}

export const termsTest = ({
  giftItem,
  organisation,
}: ITestParams): string | false => {
  if (giftItem && organisation) {
    const stripped = stripHTML(giftItem.terms || '')
    return (
      !(organisation.format === OrganisationFormat.GiftVouchers)
      && !stripped
      && 'Item requires description'
    )
  }
  return false
}

export const startDateTest = ({
  giftItem,
  organisation,
}: ITestParams): string | false => {
  if (
    giftItem
    && organisation
    && organisation.format === OrganisationFormat.SpecialOffers
    && !giftItem.startDate
  ) {
    return 'Item requires a start date'
  }
  return false
}

export const expiryTest = ({
  giftItem,
  organisation,
}: ITestParams): string | false => {
  if (
    giftItem
    && organisation
    && organisation.format === OrganisationFormat.SpecialOffers
    && giftItem.expiryDate
  ) {
    const today = moment().startOf('day').unix()
    const expiry = moment.unix(giftItem.expiryDate).startOf('day').unix()
    if (expiry < today) {
      return 'Expiry date must be in the future'
    }
  }
  return false
}

export const unpublishDateTest = ({
  giftItem,
  organisation,
}: ITestParams): string | false => {
  if (
    giftItem
    && organisation
    && organisation.format !== OrganisationFormat.GiftVouchers
    && giftItem.unpublishDate
  ) {
    const today = moment().startOf('day').unix()
    const unpublish = moment.unix(giftItem.unpublishDate).startOf('day').unix()
    if (unpublish < today) {
      return 'Unpublish date must be in the future'
    }
  }
  return false
}

const allTests = ({
  giftItem,
  organisation,
  unfinishedOnly,
}: ITestParams): string | false => {
  const params = { giftItem, organisation }

  const unfinishedAndExpired = !unfinishedOnly
    ? expiryTest(params) || unpublishDateTest(params)
    : false

  return (
    itemTest(params)
    || valueTest(params)
    || nameTest(params)
    || typeTest(params)
    || categoryTest(params)
    || imageTest(params)
    || validityTest(params)
    || monetaryTest(params)
    || descriptionTest(params)
    || detailsTest(params)
    || restrictionsTest(params)
    || startInfoTest(params)
    || originalPriceTest(params)
    || termsTest(params)
    || startDateTest(params)
    || unfinishedAndExpired
  )
}

export const isGiftItemValid = (
  giftItem: IGiftItemResponse | null,
  organisation: IOrganisationResponse | null,
  unfinishedOnly?: true,
): boolean => {
  if (allTests({ giftItem, organisation, unfinishedOnly })) return false
  return true
}

export const getGiftItemStatus = (
  giftItem: IGiftItemResponse | null,
  organisation: IOrganisationResponse | null,
): string => allTests({ giftItem, organisation })
|| giftItem?.status
  || GiftItemStatus.Unpublished
