import { colors } from '@one-tree/library'
import React, { ReactElement } from 'react'
import { Link, useLocation } from 'react-router-dom'
import styled from 'styled-components'

const StyledDiv = styled.div`
  color: ${colors.darkerGray};
  position: absolute;
  top: 6px;
  left: 8px;
  cursor: default;

  a,
  .fakeLink {
    display: inline-block;
    text-decoration: none;
    color: inherit;
    font-size: 0.9rem;
    transition: 0.2s;
  }

  a:hover {
    color: ${colors.black};
  }
`

export default function Breadcrumbs(): ReactElement | null {
  const location = useLocation()
  const segments = location.pathname.split('/').filter(Boolean)

  const fromSales = location.state && location.state.from === 'sales'
  const fromSearch = location.state && location.state.search

  if (segments.length <= 0) return null

  return (
    <StyledDiv>
      <Link to="/">Home</Link>

      {fromSales && segments.length > 0 && (
        <span>
          {' \u203a '}
          <Link to="/sales">Sales</Link>
        </span>
      )}

      {fromSearch && segments.length > 0 && (
        <span>
          {' \u203a '}
          <Link to={`/search-results${fromSearch}`}>Search results</Link>
        </span>
      )}

      {segments.map((segment: string, index: number) => {
        const path = `/${segments.slice(0, index + 1).join('/')}`
        const displayText = segment.replace(/-/g, ' ')
        const isLast = index === segments.length - 1

        if (segment === 'voucher-detail') return null

        return (
          <span key={path}>
            {' \u203a '}
            {isLast ? (
              <span className="fakeLink">
                {displayText.charAt(0).toUpperCase() + displayText.slice(1)}
              </span>
            ) : (
              <Link to={path}>
                {displayText.charAt(0).toUpperCase() + displayText.slice(1)}
              </Link>
            )}
          </span>
        )
      })}
    </StyledDiv>
  )
}
