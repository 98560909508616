import { colors } from '@one-tree/library'
import React, { CSSProperties, ReactElement } from 'react'
import styled from 'styled-components'
import useResponsive from '../../helpers/isResponsive'
import { PropsWithChildren } from '../../types/Types'

const Styles = styled.div`
  position: relative;
  background-color: ${colors.white};
  color: ${colors.black};
  border-radius: 5px;
  margin: 20px auto;

  p {
    margin-top: 0;
  }
  width: fit-content;
  &.card-size-full {
    max-width: 1800px;
    width: auto;
  }
  &.card-size-big {
    width: 1100px;
    width: ${(props): string => props.theme.isSmallDesktop && 'auto'};
  }
  &.card-size-medium {
    max-width: 860px;
    width: 860px;
    width: ${(props): string => props.theme.isSmallDesktop && 'auto'};
  }
  &.card-size-small {
    max-width: 450px;
    width: 450px;
  }
`
export enum CardSize {
  Full = 'full',
  Big = 'big',
  Medium = 'medium',
  Small = 'small',
}
interface CardProps {
  className?: string
  style?: CSSProperties
  cardSize?: CardSize
}
function Card(props: PropsWithChildren<CardProps>): ReactElement {
  const {
    children, className, style, cardSize,
  } = props

  return (
    <Styles
      className={`${className || ''} ${`card-size-${cardSize}`}`}
      style={style}
      theme={useResponsive()}
    >
      {children}
    </Styles>
  )
}
export default Card
